@import '../vars';

@media (max-width: $md * 1px) {
  .mainContent {
    width: calc(100% - 10px);
    padding: 60px 5px 5px 5px;
  }

  .headerLayout {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  .contracted {
    background-color: rgba(255, 255, 255, 1);
    width: 50px;
    height: 60px;
    .headerLink {
      visibility: hidden;
    }
    .headerLinkActive {
      visibility: hidden;
    }
    .headerLogo {
      visibility: hidden;
    }
  }
  .expanded {
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.95);
    .headerLink {
      visibility: visible;
    }
    .headerLinkActive {
      visibility: visible;
    }
    .headerLogo {
      padding: 0 0 0 24px;
    }
  }
  .hamburgerMenu {
    visibility: visible;
    cursor: pointer;
    position: relative;
    width: 60px;
    left: calc(100vw - 60px);
  }
  .openMenu {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    & :nth-child(1) {
      font-size: 20px;
      padding: 7px 5px 0 0;
    }
    & :nth-child(2) {
      font-size: 30px;
    }
  }
  .closedMenu {
    text-align: center;

    & :nth-child(1) {
      font-size: 20px;
    }
    & :nth-child(2) {
      font-size: 30px;
    }
  }
}
@media (min-width: $md * 1px) {
  .mainContent {
    width: calc(100% - 10px);
    padding: 5px;
  }

  .headerLayout {
    max-width: $maxWidth * 1px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }
  .expanded,
  .contracted {
    width: 100%;
    .headerLink {
      visibility: visible;
    }
    .headerLinkActive {
      visibility: visible;
    }
    .headerLogo {
      padding: 0 0 0 10px;
    }
  }
  .hamburgerMenu {
    visibility: hidden;
  }
}

.mainContent {
  margin: 0 auto;
}

.headerLogo {
  img {
    width: 50px;
    height: auto;
  }
}
.headerLink {
  font-size: 18px;
  font-weight: 400;
  margin-left: 24px;
  padding-top: 30px;
  a {
    color: #000000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.headerLinkActive {
  font-size: 18px;
  font-weight: 400;
  color: #f1dd81;
  margin-left: 24px;
  padding-top: 30px;
}
