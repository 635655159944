@import '../../vars';

@media (max-width: $md * 1px) {
  .detailsOuterContainer {
    display: flex;
    flex-direction: column;
  }
  .mediaDetailsContainer {
    width: 100%;
  }
  .imageDetailsContainer {
    height: calc(100vw - 40px);
  }
  .contentDetailsContainer {
    width: 100%;
  }
  .captionsBody {
    height: auto;
    min-height: 40px;
  }
  .authorNameContainer {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: $md * 1px) {
  .detailsOuterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .mediaDetailsContainer {
    width: 50%;
  }
  .imageDetailsContainer {
    height: 370px;
  }
  .contentDetailsContainer {
    width: calc(50% - 20px);
    padding-left: 20px;
  }
  .authorNameContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .captionsBody {
    height: 340px;
  }
}

.mediaDetailsContainer {
  background-image: linear-gradient(#F2F2F2, #FAFAFA);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.imageDetailsContainer {
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.audioDetailsContainer {

}

.captionsTitle {
  background-color: #F2F2F2;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px 5px 0 0;
}
.captionsBody {
  background-image: linear-gradient(#F2F2F2, #FAFAFA);
  padding: 10px;
  border-radius: 0 0 5px 5px;
}


.authorBody {
  margin-top: 20px;
  font-size: 16px;
}
.reportThisImage {
  color: #a52a2a;
  margin-top: 20px;
  cursor: pointer;
}
