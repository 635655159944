@import '../vars';

@media (max-width: $md * 1px) {
  .mapBlockMain {
    height: auto;
  }
  .filtersTitle {
    font-size: 18px;
    margin-right: 40px;
  }
  .filtersSelector {
    margin-right: 16px;
  }
  .checkmarkContainer {
    padding-left: 24px;
    margin-bottom: 12px;
    font-size: 16px;
  }
  .checkmark {
    height: 18px;
    width: 18px;
  }
  .checkmarkContainer .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
  }
  .addYours {
    border-radius: 20px;
    top: 5px;
    left: calc(50% - 75px);
  }

  .closeAddYours {
    border-radius: 20px;
    top: 5px;
    left: calc(50% - 75px);
  }
  .initLeftButton {
    visibility: visible;
  }
  .hideLeftButton {
    visibility: hidden;
  }
  .showLeftButton {
    visibility: visible;
  }
  .initRightButton {
    opacity: 0;
    visibility: hidden;
  }
  .hideRightButton {
    visibility: hidden;
  }
  .showRightButton {
    visibility: visible;
  }
  .myMapContainer {
    top: 60px;
  }
}

@media (min-width: $md * 1px) {
  .mapBlockMain {
    min-height: 750px;
    height: calc(100vh - 140px);
  }

  .filtersTitle {
    font-size: 22px;
    margin-right: 40px;
  }
  .filtersSelector {
    margin-right: 20px;
  }
  .checkmarkContainer {
    padding-left: 35px;
    margin-bottom: 12px;
    font-size: 22px;
  }
  .checkmark {
    height: 25px;
    width: 25px;
  }
  .checkmarkContainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
  }

  .addYours {
    border-radius: 20px 0 0 20px;
    top: 190px;
  }

  .closeAddYours {
    border-radius: 0 20px 20px 0;
    top: 135px;
  }
  .initLeftButton {
    right: -1px;
  }
  .hideLeftButton {
    right: -1px;
    animation: hideLeftBtnAnim 0.5s forwards;
    animation-timing-function: ease-out;
  }
  .showLeftButton {
    right: -140px;
    animation: showLeftBtnAnim 0.5s forwards;
    animation-timing-function: ease-in;
    animation-delay: 0.5s;
  }
  .initRightButton {
    left: -140px;
  }
  .hideRightButton {
    left: -1px;
    animation: hideRightBtnAnim 0.5s forwards;
    animation-timing-function: ease-in;
  }
  .showRightButton {
    left: -140px;
    animation: showRightBtnAnim 0.5s forwards;
    animation-timing-function: ease-in;
    animation-delay: 0.5s;
  }
  .myMapContainer {
    top: 84px;
  }
}

.mapBlockMain {
  width: 100%;
  background: linear-gradient( 180deg, #f1dd81, #FFFFFF);
  border-radius: 5px 5px 0px 0px;
}


.zoomBtn {
  background-color: #FFFFFF;
  border-radius: 5px;
  width: 36px;
  height: 38px;
  margin-left: 10px;
  font-size: 30px;
  color: #666666;
  padding-top: 5px;
  padding-left: 6px;
  cursor: pointer;

  &:hover {
    background-color: #d3c16f;
    color: #FFFFFF;
  }
}


.filterContainer {
  width: calc(100% - 40px);
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
}


.filters {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}



.checkmarkContainer {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmarkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
}

/* On mouse-over, add a grey background color */
.checkmarkContainer:hover input ~ .checkmark {
  background-color: #a5af79;
}

/* When the checkbox is checked, add a blue background */
.checkmarkContainer input:checked ~ .checkmark {
  background-color: #a5af79;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmarkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmarkContainer .checkmark:after {
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}






%add {
  width: 120px;
  height: 36px;

  background-color: #FFFFFF;
  border: 1px solid #f1dd81;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 5px 10px 5px;
  cursor: pointer;

  .addYoursText {
    padding-top: 5px;
    font-size: 20px;
    color: #000000;
  }
  .addYoursCircle {
    background-color: #f1dd81;
    border-radius: 50%;
    width: 35px;
    height: 27px;
    padding-top: 8px;
    text-align: center;
  }

  &:hover {
    background-color: #f1dd81;
    border: 1px solid #FFFFFF;
    .addYoursText {
      color: #FFFFFF;
    }
    .addYoursCircle {
      background-color: #FFFFFF;
    }
  }
}


.hoodmapsTag {
  font-weight: 900;
  -webkit-text-fill-color: #f1dd81;
  /*-webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #a5af79;*/
  text-shadow: 2px 2px 3px #656b4b;
}

.imageTag {
  font-size: 32px;
  &:hover {
    color: #FFFFFF;
  }
  img {
    width: 40px;
    height: auto;
    border: 1px solid #f1dd81;

    &:hover {
      border: 2px solid #000000;
    }
  }
}


.myMapContainer {
  position: absolute;
  left: 100vw;
  width: 100vw;
  height: calc(100vh - 100px);
  background-color: #FFFFFF;
  z-index: 100;
}

.addYours {
  @extend %add;
  position: absolute;
  z-index: 110;
}

.closeAddYours {
  @extend %add;
  position: absolute;
  z-index: 120;
}


.popContainerIn {
  animation: slideIn 0.5s forwards;
  animation-timing-function: ease-out;
}
.popContainerOut {
  animation: slideOut 0.5s forwards;
  animation-timing-function: ease-in;
}

@keyframes slideIn {
  from {left: 100vw;}
  to {left: 0px;}
}
@keyframes slideOut {
  from {left: 0px;}
  to {left: 100vw;}
}



@keyframes hideLeftBtnAnim {
  from {right: -1px;}
  to {right: -140px;}
}
@keyframes showLeftBtnAnim {
  from {right: -140px;}
  to {right: -1px;}
}

@keyframes hideRightBtnAnim {
  from {left: -1px;}
  to {left: -140px;}
}

@keyframes showRightBtnAnim {
  from {left: -140px;}
  to {left: -1px;}
}

