@import '../vars';

@media (max-width: $md * 1px) {
  .instructionsContainer {
    padding-top: 0px 10px;
  }
}
@media (min-width: $md * 1px) {
  .instructionsContainer {
    padding-top: 40px 10px;
  }
}
.instructionsContainer {
  width: calc(100% - 20px);
  text-align: justify;
  max-width: 960px;
  margin: 0 auto;

  > div {
    margin: 20px 0;
  }
}
