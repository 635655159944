@import '../../vars';

@media (max-width: $md * 1px) {
  .instructions {
    padding: 20px;
    font-size: 16px;
    left: 3%;
    right: 3%;
    top: 20px;
    padding: 10px;
  }
  .hideLine {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (min-width: $md * 1px) {
  .instructions {
    padding: 20px;
    font-size: 20px;
    left: 10%;
    right: 10%;
    top: 20px;
    padding: 20px 40px;
  }

  .hideLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.instructions {
  position: absolute;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.8);

  border-radius: 10px;
  color: #ffffff;


  .title {
    margin-top: 20px;
    font-weight: 500;
    font-size: 24px;
  }
  .subTitle {
    font-weight: 500;
  }
  .introBody {
    margin: 20px 0;

    .imagify {
      font-style: italic;
      font-weight: 700;
    }
  }

  .hideLine {
    display: flex;
    .hidePermenantly{
      margin-bottom: 20px;
    }
    .hideNow {
      background-color: #ffffff;
      color: #000000;
      padding: 10px 40px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;

      &:hover {
        background-color: #cccccc;
      }
    }
  }
}

.hide {
  opacity: 0;
}
.show {
  opacity: 0;
  animation: showInstructions 0.5s forwards;
  animation-timing-function: ease-out;
  animation-delay: 1s;
}

@keyframes showInstructions {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
