@import '../vars';

@media (max-width: $md * 1px) {
  .popupContainer {
    font-size: 20px;
    left: 2%;
    right: 2%;
    top: 70px;
    height: auto;
    border-radius: 10px;
    color: #000000;
    padding: 10px 10px;
    box-shadow: 5px 5px 5px rgba(75, 75, 75, 0.6);
    display:flex;
    flex-direction: column-reverse;
  }
  .popupInnerContainer {
    width: calc(100% - 20px);
    margin: 5px auto;
    padding: 0 5px;
  }
  .emptyPadding {
    width: 100%;
    font-size: 20px;
    text-align: right;
  }
}
@media (min-width: $md * 1px) {
  .popupContainer {
    font-size: 20px;
    left: 15%;
    right: 15%;
    top: 200px;
    height: 520px;
    border-radius: 10px;
    color: #000000;
    padding: 10px 10px;
    box-shadow: 5px 5px 5px rgba(75, 75, 75, 0.6);

    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .popupInnerContainer {
    width: calc(100% - 52px);
    margin: 40px auto;
    padding: 0 20px;
  }
  .emptyPadding {
    width: 26px;
    font-size: 24px;
  }
}
.popupContainer {
  position: absolute;
  z-index: 105;
  background-color: #ffffff;
  border-radius: 10px;
  color: #000000;

  padding: 10px 10px;
  box-shadow: 5px 5px 5px rgba(75, 75, 75, 0.6);
  opacity: 0;

  animation: fadeInPopup 0.5s forwards;
  animation-timing-function: ease-out;

}

@keyframes fadeInPopup {
  from {opacity: 0}
  to {opacity: 1}
}



.clicker {
  cursor: pointer;
  color: #666666;
  &:hover{
    color: #f1dd81;
  }
}
