@import '../../vars';

@media (max-width: $md * 1px) {
  .searchEyebrow {
    font-size: 16px;
    padding-top: 10px;
  }

  .flushLeft {
    margin-left: 10px;
    width: calc(100% - 20px);
  }
  .tabLeft {
    margin-left: 10px;
    width: calc(100% - 20px);
  }
  .controlsContainer {
    margin-left: 10px;
  }
  .full {
    input {
      width: calc(100% - 20px);
    }
  }

  .threeQuarters {
    input {
      width: calc(100% - 20px);
    }
  }

}

@media (min-width: $md * 1px) {
  .searchEyebrow {
    font-size: 18px;
    padding-top: 20px;
  }

  .flushLeft {
    margin-left: 20px;
    width: calc(100% - 40px);
  }
  .tabLeft {
    margin-left: calc(15% + 10px);
  }
  .controlsContainer {
    margin-left: 20px;
    margin-right: 20px;
  }
  .full {
    input {
      width: calc(100% - 10px);
    }
  }

  .threeQuarters {
    input {
      margin-right: 20px;
      width: calc(85% - 20px);
      float: right;
    }
  }
}




.controlsContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.searchInput {
  width: 100%;
  input {
    border-radius: 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    padding-left: 10px;

    &::placeholder {
      color: #888585;
      font-style: italic;
      font-size: 14px;
    }
  }
}

.autocomplete-dropdown-container {
  padding: 10px;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.suggestion-item {
  font-size: 16px;
  padding: 10px 5px;
}
.suggestion-item--active {
  font-size: 16px;
  font-style: italic;
  padding: 10px 5px;
}
