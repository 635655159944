.mapBlockAdd {
  width: 100%;
  min-height: 750px;
  height: calc(100vh - 140px);
  /*background-color: #f1dd81;*/
  background: linear-gradient(180deg, #f1dd81, #ffffff);
}
.shortInstructions {
  height: 30px;
  margin: 30px 0 0 20px;
  font-size: 18px;
}

